<script setup lang="ts">
import { AppModal, Footer } from '@mogelijk-technologies/ui-library';
import { useRoute } from 'vue-router';

import type Echo from 'laravel-echo';

export interface Props {
  isBgBlue?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isBgBlue: false,
});

const route = useRoute();
const { $echo } = useNuxtApp();

($echo as Echo).channel(`ockto-${route.params.hash.toString()}`)
  .listen('.ocktoCompleted', () => navigateTo({ path: `/ockto/${route.params.hash}/succes` }));

</script>

<template>
  <Head>
    <Title>Mogelijk - Ockto</Title>
    <Link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicons/apple-touch-icon.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
    <Link rel="manifest" href="/manifest.json" />
    <Meta name="msapplication-TileColor" content="#da532c" />
    <Meta name="theme-color" content="#ffffff" />
  </Head>
  <main id="mogelijk__ui-library" class="min-h-screen w-full flex flex-col" :class="{'bg-white': !props.isBgBlue}">
    <header class="w-full flex justify-center p-6">
      <div class="base:flex tablet:block w-fit justify-center">
        <img
          src="/android-chrome-192x192.png"
          alt="mogelijk vastgoed"
          class="w-8 tablet:w-12"
        />
        <img
          src="/mogelijk-logo-text.svg"
          alt="mogelijk vastgoed"
          width="160"
          class="top-0 ml-2 mt-2 hidden w-28 overflow-hidden tablet:inline"
        />
      </div>
    </header>
    <div class="flex h-full min-h-full flex-1 flex-col items-center">
      <slot />
      <AppModal />
    </div>
    <div class="w-full max-w-7xl mx-auto px-2 sm:!px-4">
      <Footer class="justify-self-end" />
    </div>
  </main>
</template>
